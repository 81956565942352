html, body {
    height: 99vh;
  }
  body {
    display: flex;
    align-items: stretch;
  }
  
  #root, .top {
    width: 100%;
    height: 97%;
  }
  #app, #app>div {
    height: 100%;
  }

h2{
	font-family: segoe ui,lucida grande,Arial,sans-serif; 
}
h3, h4, h5, h6{
	font-family: segoe ui,lucida grande,Arial,sans-serif; 
	line-height: 10px; 
}
h3{
    display: inline-block;
}

b{
	font-family: segoe ui,lucida grande,Arial,sans-serif; 
	line-height: 30px; 
}

#first {
    background-color: #dde;
    width: 100%;
    height: 100%;
    min-width: 10px;
}

#second {
	position: relative;
    background-color: #eee;
    width: 100%-8px;
    height: 100%;
    min-width: 10px;
	padding-left: 5px;
    padding-right: 5px;
    overflow: "auto";
}

.third {
    background-color: #eee;
	padding-left: 5px;
    padding-right: 5px;
    overflow: "auto";
    height: 100%;
}


#echo_file {
	padding-left: 10px;
    padding-right: 10px;
	padding-top: 1px; 
    padding-bottom: 10px;
  	font-family: segoe ui,lucida grande,Arial,sans-serif; 

  /*
	white-space: pre-wrap;

	overflow-y: auto;
  */
}


.resizable {
    overflow: auto;
    resize: both;
    height: auto;
    width: auto;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
     -moz-box-sizing: border-box;    /* Firefox, other Gecko */
     box-sizing: border-box;         /* Opera/IE 8+ */
}

#output{
    resize: none;
    outline: none;
    width: calc(100% - 10px);
    padding: 10px;
    height: calc(50% - 10px);
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
     -moz-box-sizing: border-box;    /* Firefox, other Gecko */
     box-sizing: border-box;         /* Opera/IE 8+ */
}

.Resizer {
    box-sizing: border-box;
    background: #000;
    opacity: 0.5;
    z-index: 1;
    background-clip: padding-box;
  }
  
  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  
  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
  
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
  
  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
  
  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }